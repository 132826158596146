import { default as indexVEIWrI2kfrMeta } from "/home/lisov/web/binance-ts/pages/dashboard/index.vue?macro=true";
import { default as script5Kj8KdEEUEMeta } from "/home/lisov/web/binance-ts/pages/dashboard/script.ts?macro=true";
import { default as indexUW8YyvUFYBMeta } from "/home/lisov/web/binance-ts/pages/index/index.vue?macro=true";
import { default as script7rjej6P7STMeta } from "/home/lisov/web/binance-ts/pages/index/script.ts?macro=true";
export default [
  {
    name: indexVEIWrI2kfrMeta?.name ?? "dashboard",
    path: indexVEIWrI2kfrMeta?.path ?? "/dashboard",
    meta: indexVEIWrI2kfrMeta || {},
    alias: indexVEIWrI2kfrMeta?.alias || [],
    redirect: indexVEIWrI2kfrMeta?.redirect || undefined,
    component: () => import("/home/lisov/web/binance-ts/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: script5Kj8KdEEUEMeta?.name ?? "dashboard-script",
    path: script5Kj8KdEEUEMeta?.path ?? "/dashboard/script",
    meta: script5Kj8KdEEUEMeta || {},
    alias: script5Kj8KdEEUEMeta?.alias || [],
    redirect: script5Kj8KdEEUEMeta?.redirect || undefined,
    component: () => import("/home/lisov/web/binance-ts/pages/dashboard/script.ts").then(m => m.default || m)
  },
  {
    name: indexUW8YyvUFYBMeta?.name ?? "index",
    path: indexUW8YyvUFYBMeta?.path ?? "/",
    meta: indexUW8YyvUFYBMeta || {},
    alias: indexUW8YyvUFYBMeta?.alias || [],
    redirect: indexUW8YyvUFYBMeta?.redirect || undefined,
    component: () => import("/home/lisov/web/binance-ts/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: script7rjej6P7STMeta?.name ?? "index-script",
    path: script7rjej6P7STMeta?.path ?? "//script",
    meta: script7rjej6P7STMeta || {},
    alias: script7rjej6P7STMeta?.alias || [],
    redirect: script7rjej6P7STMeta?.redirect || undefined,
    component: () => import("/home/lisov/web/binance-ts/pages/index/script.ts").then(m => m.default || m)
  }
]